import { useState } from "react";
import * as React from 'react';
import { useEthers, useEtherBalance } from "@usedapp/core";
import { utils } from "ethers";

import { useCount, useContractMethod } from "../hooks";


export default function Count(props) {
    const count = useCount();
    const { activateBrowserWallet, account } = useEthers();
    const [input, setInput] = useState("");
    const bobcat = "hello";
    const { state: setMintTeaState, send: mintIt } = useContractMethod("mintTea");

    function handleMint() {

        if (!account) {
            console.log("No Account Connected");
            return;
        }
        console.log(props.tankNumber);
        if (props.tankNumber < 1) {
            console.log("No Items chosen");
            return;
        }

        mintIt(account, props.tankNumber, {
            value: utils.parseEther(props.orderValue.toString()),
        });        
    }

    return account ? (
        <div className="mintSection">
            <button onClick={handleMint}>
                Mint
            </button>
            <br />
            <label>
                Minted: {count ? count.toNumber() : 0}/10,000
            </label>
            <br/>
            
            <div className="errorMessage">
                {setMintTeaState ? (setMintTeaState.errorMessage) : (<div />
                )}
            </div>
        </div>
            
    ) :
        (<div />
        );


}