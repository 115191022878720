import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
//import Identicon from "./Identicon";
import * as React from 'react';

type Props = {
    handleOpenModal: any;
};

export default function ConnectButton({ handleOpenModal }: Props) {
    const { activateBrowserWallet, account } = useEthers();
    const etherBalance = useEtherBalance(account);

    function handleConnectWallet() {
        activateBrowserWallet();
    }

    return account ? (
        <div>
                <label>
                    {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH
                </label>
            <button
                onClick={handleOpenModal}

            >
                    {account &&
                        `${account.slice(0, 6)}...${account.slice(
                            account.length - 4,
                            account.length
                        )}`}

            </button>
            </div>
    ) : (
            <div>
            <br/>
            <button onClick={handleConnectWallet}>

                Connect to a wallet

                </button>
                </div>
    );
}
