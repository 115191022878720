import * as React from 'react';
import { Component } from 'react';

import forceNumber from 'force-number';
import { RangeStepInput } from 'react-range-step-input';

interface SliderProps {
    minVal: number
    maxVal: number
    price: number
    title: string
    update: Function
}

interface SliderState {
    value: number
}

class MySlider extends Component<SliderProps, SliderState> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: 0
        };
        this.onChange = this.onChange.bind(this);
    }
    render() {
        return <div>
            <h3><i>{this.props.title}</i></h3>

            <br />
            <div className="sliderDiv">
                <div className="minValLabel">
                    {this.props.minVal}
                </div>
            <RangeStepInput
                    min={forceNumber(this.props.minVal)} max={forceNumber(this.props.maxVal)}
                    value={this.state.value} step={1}
                    onChange={this.onChange.bind(this)}/>
            
               
                <div className="maxValLabel">
                    {this.props.maxVal}
                </div>

                <div className="centralText">
                    <p>{this.state.value} @ {this.props.price} Ξ</p>
                    <h3>{Math.round(this.props.price * 100 * this.state.value) / 100} Ξ</h3>
                </div>
            </div>
            <br/>
            <br/>
        </div>;
    };
    onChange(e: any) {
        if (e) {
            var newVal: number = forceNumber(e.target.value);
            this.setState({ value: newVal });

            this.props.update(newVal);

        }
    }
}

export default MySlider;