import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faDiscord,
    faTwitch
} from "@fortawesome/free-brands-svg-icons";

export default function SocialFollow() {
    return (
        <div className="social-bar">
            <div className="social-button-container">
                <a href="https://discord.com/"
                    className="discord social">
                    <FontAwesomeIcon icon={faDiscord} size="1x" />
                </a>
                <a href="https://twitter.com/" className="twitter social">
                    <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
                <a href="https://www.instagram.com/"
                    className="instagram social">
                    <FontAwesomeIcon icon={faInstagram} size="1x" />
                </a>
                <a href="https://www.twitch.tv/"
                    className="twitch social">
                    <FontAwesomeIcon icon={faTwitch} size="1x" />
                </a>
                <a href="https://opensea.io/"
                    className="opensea social">
                    <img className="openseaIcon" src={"https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.png"} width="18px" height="18px" alt="this is car image" />
                </a>
            </div>
        </div>
    );
}