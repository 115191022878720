import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
const Hero = () => (

    <div id="heroDivFooter">
        <video src="videos/tankpreview4.mp4" autoPlay loop playsInline muted/>
        <img src="images/NFTlogo-big-01c.png" className="logoHeroMain" />

        <div id="heroButtons">

            <NavLink tag={Link} className="heroButton" to="/tank">TANK DEMO</NavLink>

            <NavLink tag={Link} className="heroButtonTwo" to="/mint">BUY TANK</NavLink>
        </div>

    </div>

);

export default connect()(Hero);
